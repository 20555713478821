<template>
  <accommodation
    :steps-config="accommodationConfig.fields[0]"
    :is-loading="isLoading || isLoadingAccomodation"
    @click:next-on-blank-modal="onClickBlank"
    @click:back="$router.push({ name: 'confirmation' })"
    @click:send-preferences="sendPreferences"
  />
</template>

<script setup>
import { onMounted } from "vue"
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedMutations,
} from "vuex-composition-helpers/dist"

import { useTracking } from "@/composables"
import { useStoreAction } from "@/composables"
import { Time } from "@/consts"

import Accommodation from "./components/accommodation.vue"

const { accommodationConfig } = useNamespacedGetters("company", [
  "accommodationConfig",
])
const {
  sendAccommodationPreferences,
  isLoading: isLoadingAccomodation,
  hasError: hasErrorAccomodation,
} = useStoreAction("reservation", "sendAccommodationPreferences")
const { next, isLoading } = useStoreAction("flow/guest", "next")
const { sendNotification } = useNamespacedActions("notifications", [
  "sendNotification",
])

const { trackEvent } = useTracking()
const { setStep } = useNamespacedMutations("flow/guest", ["setStep"])

setStep("confirmation")
onMounted(() => trackEvent("step_accomodation_preferences"))

const sendPreferences = async (preferences) => {
  await sendAccommodationPreferences(preferences)

  if (hasErrorAccomodation.value) {
    sendNotification({
      message: "Erro ao enviar preferências de acomodação",
      duration: 4 * Time.Seconds,
      color: "var(--red-500)",
    })
    return
  }
  await next()
}

const onClickBlank = async () => {
  await next()
}
</script>
