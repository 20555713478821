<!-- eslint-disable max-lines -->
<template>
  <div>
    <z-card width="100%">
      <template #content>
        <div>
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'text-uppercase' : ''"
            width="100%"
          >
            <span class="body-bold">{{
              $t("reservation.accommodation_page_title")
            }}</span>
          </div>
          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'my-5 font-weight-bold'
                : 'mt-5 font-weight-bold'
            "
          >
            <p class="body-text">
              {{ $t("reservation.accommodation_data_title") }}
            </p>
          </div>
          <div>
            <p
              class="body-text"
              v-html="$t('reservation.accommodation_data_description')"
            ></p>
          </div>

          <div
            v-if="
              stepsConfig.typeofRoom.floorPreferences ||
              stepsConfig.typeofRoom.elevatorPreferences
            "
            class="d-flex align-center mt-6 justify-space-between"
          >
            <p class="font-weight-bold mr-6">
              {{ $t("reservation.accommodation_type_title") }}
            </p>
            <v-divider />
          </div>

          <div
            class="card-desktop"
            :style="
              (!stepsConfig.typeofRoom.floorPreferences &&
                stepsConfig.typeofRoom.elevatorPreferences) ||
              (stepsConfig.typeofRoom.floorPreferences &&
                !stepsConfig.typeofRoom.elevatorPreferences)
                ? 'display:inherit'
                : 'display:flex'
            "
          >
            <div class="card-container">
              <div
                v-for="typeRoom in typeRooms.floorPreference"
                :key="typeRoom.id"
                :class="
                  typeRoom.selected == true ? 'cards cards-selected' : 'cards'
                "
                :style="typeRoom.disabled ? { opacity: 0.5 } : {}"
                @click="selectTypeRoomPreference(typeRoom, 'floorPreference')"
              >
                <z-icon
                  class="mt-2"
                  :color="typeRoom.selected == true ? 'white' : 'black'"
                  size="30px"
                >
                  {{ typeRoom.icon }}
                </z-icon>
                <p :class="$vuetify.breakpoint.smAndDown ? 'body2' : 'body3'">
                  {{ $t(typeRoom.title) }}
                </p>
              </div>
            </div>

            <div class="card-container">
              <div
                v-for="typeRoom in typeRooms.elevatorPreference"
                :key="typeRoom.id"
                :class="
                  typeRoom.selected == true ? 'cards cards-selected' : 'cards'
                "
                :style="typeRoom.disabled ? { opacity: 0.5 } : {}"
                @click="
                  selectTypeRoomPreference(typeRoom, 'elevatorPreference')
                "
              >
                <z-icon
                  class="mt-3"
                  :color="typeRoom.selected == true ? 'white' : 'black'"
                  size="30px"
                >
                  {{ typeRoom.icon }}
                </z-icon>
                <p :class="$vuetify.breakpoint.smAndDown ? 'body2' : 'body3'">
                  {{ $t(typeRoom.title) }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="stepsConfig.checkinTime.length > 0"
            class="d-flex align-center mt-6 justify-space-between"
          >
            <p class="font-weight-bold mr-6">
              {{ $t("reservation.accommodation_checkin_time") }}
            </p>
            <v-divider />
          </div>
          <div
            v-if="stepsConfig.checkinTime.length > 0"
            class="times-container"
          >
            <div
              v-for="checkinTime in checkinTimes"
              :key="checkinTime.id"
              class="times"
              :class="
                checkinTime.selected == true ? 'times times-hover' : 'times'
              "
              :style="
                checkinTime.disabled
                  ? { opacity: 0.5, pointerEvents: 'none' }
                  : {}
              "
              @click="selectCheckinTime(checkinTime)"
            >
              <p>{{ checkinTime.id }}</p>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-md-row-reverse justify-start gap-24 mt-6"
          >
            <z-btn
              primary
              :text="$t('reservation.send')"
              :is-loading="isLoading"
              @click="sendPreferencesOrGoToNextStep"
            />
            <z-btn
              :text="$t('reservation.back_button')"
              :disabled="isLoading"
              @click="$emit('click:back')"
            />
          </div>

          <z-card-modal
            v-model="isBlankModalOpened"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '520px'"
            title="Página em Branco"
          >
            <template #content>
              <div class="d-flex flex-column gap-20">
                <p class="body-text">
                  {{ $t("reservation.accommodation_modal_sure_text") }}
                </p>
                <p class="body-text">
                  {{ $t("reservation.accommodation_modal_info_text") }}.
                </p>
              </div>

              <div
                class="d-flex flex-column flex-md-row-reverse justify-start gap-20 mt-5"
              >
                <z-btn
                  primary
                  :text="$t('reservation.send')"
                  @click="$emit('click:next-on-blank-modal')"
                />
                <z-btn
                  :text="$t('reservation.back_button')"
                  @click="isBlankModalOpened = false"
                />
              </div>
            </template>
          </z-card-modal>
        </div>
      </template>
    </z-card>
  </div>
</template>
<script>
import { ref, toRefs } from "vue"

export default {
  props: {
    stepsConfig: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isBlankModalOpened = ref(false)
    const { stepsConfig } = toRefs(props)

    const preparedTypeRoom = () => {
      const floorPreference = []
      const elevatorPreference = []
      const typeRoomsObject = stepsConfig.value
      let key = ""

      if (typeRoomsObject.typeofRoom.floorPreferences) {
        floorPreference.push({
          id: "high",
          title: "reservation.accommodation_floor_higher",
          icon: "$highest_level",
          disabled: typeRoomsObject[key] === false ? true : false,
          selected: false,
        })
        floorPreference.push({
          id: "low",
          title: "reservation.accommodation_floor_lower",
          icon: "$lowest_level",
          disabled: typeRoomsObject[key] === false ? true : false,
          selected: false,
        })
      }
      if (typeRoomsObject.typeofRoom.elevatorPreferences) {
        elevatorPreference.push({
          id: true,
          title: "reservation.accommodation_near_elevator",
          icon: "$near_the_elevator",
          disabled: typeRoomsObject[key] === false ? true : false,
          selected: false,
        })
        elevatorPreference.push({
          id: false,
          title: "reservation.accommodation_far_elevator",
          icon: "$far_from_elevator",
          disabled: typeRoomsObject[key] === false ? true : false,
          selected: false,
        })
      }

      return { floorPreference, elevatorPreference }
    }

    const typeRooms = ref(preparedTypeRoom())

    const selectTypeRoomPreference = (field, param) => {
      const typeRoom = typeRooms.value[param]

      typeRoom.forEach((key) => {
        if (key.id === field.id) {
          key.selected = !key.selected
        } else key.selected = false
      })

      const selectedCount = typeRoom.filter((key) => key.selected).length
      typeRoom.forEach((key) => {
        if (selectedCount !== 0) {
          if (key.selected === false) {
            key.disabled = true
          } else key.disabled = false
        } else key.disabled = false
      })
    }

    const preparedCheckinTime = () => {
      const checkinTimes = []
      const checkinTimesObject = stepsConfig.value.checkinTime

      for (const key of checkinTimesObject) {
        checkinTimes.push({
          id: key,
          selected: false,
          disabled: checkinTimesObject[key] === false ? true : false,
        })
      }

      return checkinTimes
    }

    const checkinTimes = ref(preparedCheckinTime())

    const selectCheckinTime = (field) => {
      checkinTimes.value.forEach((key) => {
        if (key.id === field.id) {
          key.selected = !key.selected
        } else key.selected = false
      })
    }

    const prepareReservationPreferences = () => {
      const nearElevator = typeRooms.value.elevatorPreference.find(
        (room) => room.selected
      )
      const floor = typeRooms.value.floorPreference.find(
        (room) => room.selected
      )
      const estimatedArrivalTime = checkinTimes.value.find(
        (time) => time.selected
      )

      const preferences = {}
      if (nearElevator?.id != null) preferences.nearElevator = nearElevator.id
      if (floor?.id) preferences.floor = floor.id
      if (estimatedArrivalTime?.id)
        preferences.estimatedArrivalTime = estimatedArrivalTime.id

      return preferences
    }

    const sendPreferencesOrGoToNextStep = () => {
      const preferences = prepareReservationPreferences()

      if (Object.keys(preferences).length !== 0) {
        emit("click:send-preferences", preferences)
      } else {
        isBlankModalOpened.value = true
      }
    }

    return {
      typeRooms,
      checkinTimes,
      selectCheckinTime,
      isBlankModalOpened,
      selectTypeRoomPreference,
      sendPreferencesOrGoToNextStep,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.cards {
  cursor: pointer;
  min-width: 45%;
  height: 72px;
  border: 1px solid #999999;
  border-radius: 10px;
  margin: 10px 5px;
  padding: 5px;
  align-items: center;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-size: 15px;
}
.cards-selected {
  background-color: var(--primary);
  color: #ffffff;
}
.times-container {
  display: flex;
  flex-wrap: wrap;
}
.times {
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 13px;
  padding: 5px;
}
.times-hover {
  background-color: var(--primary);
  color: #ffffff;
}
@media (min-width: 960px) {
  .cards {
    margin: 25px 5px 5px -10px;
    height: auto;
    padding: 10px 0;
  }
  .card-container {
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
  }
  .card-desktop {
    width: 700px;
    display: flex;
    align-items: flex-start;
  }
  .times-container {
    margin-top: 5px;
    margin-left: -5px;
    justify-content: flex-start;
  }

  .times {
    height: 37px;
    width: 37px;
  }
}
</style>
