var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('z-card',{attrs:{"width":"100%"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'text-uppercase' : '',attrs:{"width":"100%"}},[_c('span',{staticClass:"body-bold"},[_vm._v(_vm._s(_vm.$t("reservation.accommodation_page_title")))])]),_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'my-5 font-weight-bold'
              : 'mt-5 font-weight-bold'},[_c('p',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.$t("reservation.accommodation_data_title"))+" ")])]),_c('div',[_c('p',{staticClass:"body-text",domProps:{"innerHTML":_vm._s(_vm.$t('reservation.accommodation_data_description'))}})]),(
            _vm.stepsConfig.typeofRoom.floorPreferences ||
            _vm.stepsConfig.typeofRoom.elevatorPreferences
          )?_c('div',{staticClass:"d-flex align-center mt-6 justify-space-between"},[_c('p',{staticClass:"font-weight-bold mr-6"},[_vm._v(" "+_vm._s(_vm.$t("reservation.accommodation_type_title"))+" ")]),_c('v-divider')],1):_vm._e(),_c('div',{staticClass:"card-desktop",style:((!_vm.stepsConfig.typeofRoom.floorPreferences &&
              _vm.stepsConfig.typeofRoom.elevatorPreferences) ||
            (_vm.stepsConfig.typeofRoom.floorPreferences &&
              !_vm.stepsConfig.typeofRoom.elevatorPreferences)
              ? 'display:inherit'
              : 'display:flex')},[_c('div',{staticClass:"card-container"},_vm._l((_vm.typeRooms.floorPreference),function(typeRoom){return _c('div',{key:typeRoom.id,class:typeRoom.selected == true ? 'cards cards-selected' : 'cards',style:(typeRoom.disabled ? { opacity: 0.5 } : {}),on:{"click":function($event){return _vm.selectTypeRoomPreference(typeRoom, 'floorPreference')}}},[_c('z-icon',{staticClass:"mt-2",attrs:{"color":typeRoom.selected == true ? 'white' : 'black',"size":"30px"}},[_vm._v(" "+_vm._s(typeRoom.icon)+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.smAndDown ? 'body2' : 'body3'},[_vm._v(" "+_vm._s(_vm.$t(typeRoom.title))+" ")])],1)}),0),_c('div',{staticClass:"card-container"},_vm._l((_vm.typeRooms.elevatorPreference),function(typeRoom){return _c('div',{key:typeRoom.id,class:typeRoom.selected == true ? 'cards cards-selected' : 'cards',style:(typeRoom.disabled ? { opacity: 0.5 } : {}),on:{"click":function($event){return _vm.selectTypeRoomPreference(typeRoom, 'elevatorPreference')}}},[_c('z-icon',{staticClass:"mt-3",attrs:{"color":typeRoom.selected == true ? 'white' : 'black',"size":"30px"}},[_vm._v(" "+_vm._s(typeRoom.icon)+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.smAndDown ? 'body2' : 'body3'},[_vm._v(" "+_vm._s(_vm.$t(typeRoom.title))+" ")])],1)}),0)]),(_vm.stepsConfig.checkinTime.length > 0)?_c('div',{staticClass:"d-flex align-center mt-6 justify-space-between"},[_c('p',{staticClass:"font-weight-bold mr-6"},[_vm._v(" "+_vm._s(_vm.$t("reservation.accommodation_checkin_time"))+" ")]),_c('v-divider')],1):_vm._e(),(_vm.stepsConfig.checkinTime.length > 0)?_c('div',{staticClass:"times-container"},_vm._l((_vm.checkinTimes),function(checkinTime){return _c('div',{key:checkinTime.id,staticClass:"times",class:checkinTime.selected == true ? 'times times-hover' : 'times',style:(checkinTime.disabled
                ? { opacity: 0.5, pointerEvents: 'none' }
                : {}),on:{"click":function($event){return _vm.selectCheckinTime(checkinTime)}}},[_c('p',[_vm._v(_vm._s(checkinTime.id))])])}),0):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-md-row-reverse justify-start gap-24 mt-6"},[_c('z-btn',{attrs:{"primary":"","text":_vm.$t('reservation.send'),"is-loading":_vm.isLoading},on:{"click":_vm.sendPreferencesOrGoToNextStep}}),_c('z-btn',{attrs:{"text":_vm.$t('reservation.back_button'),"disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('click:back')}}})],1),_c('z-card-modal',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '520px',"title":"Página em Branco"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column gap-20"},[_c('p',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.$t("reservation.accommodation_modal_sure_text"))+" ")]),_c('p',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.$t("reservation.accommodation_modal_info_text"))+". ")])]),_c('div',{staticClass:"d-flex flex-column flex-md-row-reverse justify-start gap-20 mt-5"},[_c('z-btn',{attrs:{"primary":"","text":_vm.$t('reservation.send')},on:{"click":function($event){return _vm.$emit('click:next-on-blank-modal')}}}),_c('z-btn',{attrs:{"text":_vm.$t('reservation.back_button')},on:{"click":function($event){_vm.isBlankModalOpened = false}}})],1)]},proxy:true}]),model:{value:(_vm.isBlankModalOpened),callback:function ($$v) {_vm.isBlankModalOpened=$$v},expression:"isBlankModalOpened"}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }